export default {
  'merchant': {
    'form': {
      'title': '商户信息'
    },
    'result': {
      'title': '信息已更新',
      'back': '返回继续填写',
    }
  },
  'tamar_ai_account': {
    'yes': '是',
    'no': '否',
    'create_confirm': {
      'title': '亚马逊店铺选品报告授权',
      'description': '是否现在授权？',
    },
    'update_confirm': {
      'title': '亚马逊店铺选品报告授权',
      'description': '是否更新授权？',
    }
  }
};
