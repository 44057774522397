export default {
  'exactMatch': '精确匹配',
  'phraseMatch': '词组匹配',
  'broadMatch': '广泛匹配',
  'title': '搜索广告系列自动化工具',
  'subtitle': '此工具将帮助您根据您的类别和目标网页为您的搜索广告系列生成关键字和文字创意。',
  'category_name': '行业',
  'category_name_tip': '请为您的企业选择一个类别，如果您找不到合适的类别，请仅针对客户或企业。',
  'category_name_tip_regenerate': '请为您的企业填写一个类别',
  'client_name': '客户名称',
  'client_name_tip': '请输入您的客户名称，该值会替换文案中的[brand]',
  'email': '电子邮件地址',
  'email_tip': '请输入您的电子邮件以接收结果。',
  'google_manager': 'Google Manager',
  'google_customer': '广告账号',
  'detailed_product_type': '详细产品类型',
  'seed_keywords': '种子词',
  'seed_keywords_tip': '该工具将根据这些关键词，找到与扩展结果相似或相关的关键词。因此，请在此处使用效果最好的关键字（根据您的要求按投资回报率、转化率等排序）。',
  'google_campaign': 'Google Campaign',
  'budget': '预算',
  'budge_tip': '设置此广告系列的平均每日预算',
  'budge_desc': '您每月的实际支出不会超过每日预算与一个月平均天数的乘积。有些天的实际支出可能低于每日预算，而有些天的实际支出最多可能会达到每日预算的两倍',
  'target_and_bidding_strategy': '目标和出价策略',
  'optional_fields': '可选字段',
  'client_information': '客户资料',
  'btn_export': '导出结果',
  'btn_save_and_export': '保存并导出结果',
  'btn_export_keywords': '导出关键字',
  'btn_re_create': '重新创建Campaign',
  'btn_create': '新建Campaign',
  'keywords': '关键词',
  'add_keyword': '添加关键字',
  'home_page_url': '主页地址',
  'home_page_url_tip': '提取您的主页的工具，作为关键字扩展和文本创意生成的输入。\n请复制并粘贴以 http[s]:// 开头并以 "/" 结尾的整个 url。例如： https://ads.google.com/\n该工具仅支持 Shopify 网站和 Shoplazza。\n如果您的网站是由其他平台创建的，请在可选字段部分输入“详细产品类型”以启用广告组。',
  'detailed_product_type_category_name': '分类名',
  'detailed_product_type_category_path': '扩展落地页网址',
  'detailed_product_type_category_path_tip': '您不需要输入完整的 URL，但您的主要着陆页之后的部分以 "/" 开头，\n例如：\n/collections/dresses',
  'input_keyword_filter': '请输入关键字筛选',
  'polling_state': '处理完毕页面会自动更新',
  'created_at': '创建时间',
  'updated_at': '更新时间',
  'state': '状态',
  'max_to_length': '最多{num}字符',
  'campaign_title': '广告系列',
  'keywords_title': '关键字',
  'ad_group': '广告组',
  'ad_groups': '广告组',
  'keyword': '关键字',
  'final_url': '最终到达网址',
  'mobile_final_url': '移动设备最终到达网址',
  'headline': '标题',
  'backup_headline': '备选标题',
  'backup_description': '备选描述',
  'min_max_count_headline': '最少选择{min}个标题, 最多可选择{max}个标题',
  'min_max_count_description': '最少选择{min}个描述, 最多可选择{max}个描述',
  'description': '描述',
  'responsive_search_ad': '广告',
  'monthly_searches': '每月搜索量',
  'competition_index': '竞争指数',
  'low_top_of_page_bid': '低顶部出价',
  'high_top_of_page_bid': '高顶部出价',
  'continuing_export': '继续导出',
  'product_name': '产品名',
  'product_name_hint': '该值会替换文案中的[product]',
  'useCsvKeyword': '上传CSV文件',
  'csvKeywordTip': '请下载CSV模板文件，确保CSV内容第一行是\'keyword\'，编辑好后上传CSV文件，文件中的种子词会和输入的种子词合并',
  'downloadCsvTpl': '下载CSV模板文件',
  'maximumCPCBidLimit': '最大每次点击成本限制',
  'setMaximumCPCBidLimit': '设置最大每次点击成本限制（可选）',
  'targetROAS': '目标 ROAS',
  'setTargetROAS': '设置目标广告回报率（可选）',
  'targetCPA': '目标 CPA',
  'setTargetCPA': '设置目标每次操作成本（可选）',
  'yes': '是',
  'no': '否',
  'display_network': '展示广告网络',
  'view_upload_keywords': '查看上传的种子关键词',
  'delete_upload_keywords': '删除上传的种子关键词',
  'location_goal_for_target': '您希望广告在何处展示',
  'target_impression_share': '目标展示次数份额百分比 (%)',
  'max_cpc_bid_limit_for_target': '每次点击费用的最高出价上限',
  'business_type': '商业类型',
  'keyword_type': '匹配规则',
  'manager_customer_id': 'Google Ads 经理帐号',
  'customer_id': 'Customer ID',
  'merchant_center_id': 'GMC ID',
  'btn_create_campaign': '新建广告系列',
  'campaign_type': '广告系列类型',
  'merchant_id': 'Merchant ID',
  'feeds': 'Feeds',
  'all_products_from_all_feeds': '所有 Feed 中的所有产品',
  'account_settings': '账户设置',
  'gmc_tip_title': '要投放广告来宣传自己网站上的产品，请选择一个 Merchant Center 账号',
  'gmc_tip_content': '请选择提供待宣传产品的关联 Merchant Center 帐号。每个广告系列只能有一个关联帐号来提供产品，并且该关联帐号在广告系列制作完成后不可更改。',
  'name_and_location': '名称和地区',
  'campaign_name': '广告系列名称',
  'campaign_name_placeholder': '请输入广告系列名称',
  'bidding_and_budget': '出价与预算',
  'target_roas': '目标广告支出回报率',
  'set_maximum_cost_per_click_bid_limit': '设置最大每次点击成本限制（可选）',
  'cpc_bid_ceiling': '每次点击费用最高出价限制',
  'create_ad_group': '创建您的广告组',
  'ad_group_header': '广告组',
  'ad_group_placeholder': '请输入广告组名称',
  'default_max_cpc': '出价',
  'default_max_cpc_desc': '该出价是此广告组中的“所有产品”产品组使用的每次点击费用最高出价。您可以进一步细分“所有产品”，并为您创建的每个额外的产品组设置专门的出价。',
  'select_campaign_type': '选择广告类型',
  'campaign': {
    'title': '广告系列管理'
  },
  'campaign_detail': {
    'title': '广告系列详情'
  },
  'competing_websites': '竞品网站',
  'check_campaign_config_tip': '请确认各项配置都已正确填写，创建后无法在此工具更改',
  'manual_enable_campaign_tip': '广告系列已创建，请前往Google Ads启用此广告系列',
  'regenerate_content': '重新生成',
  languageId: {
    label: '语言',
    questionPopover: {
      title: '选择目标用户所使用的语言。',
      content: '利用语言定位，您可以根据用户的语言设置和网站所使用的语言来限制广告的展示范围。广告可以向了解您所选的任一语言但不一定了解您所选的全部语言的用户展示。',
    },
  },
  locationIds: {
    label: '投放国家',
    questionPopover: {
      title: '为此广告系列选择地理位置',
      content: [
        '借助地理位置定位功能，您可以将广告定位到位于某个地理位置、经常前往该地理位置或对该地理置感兴趣的用户。',
        '例如，巴黎的西饼店店主可以将广告定位到（现在或以前）位于巴黎、经常前往巴黎或对巴黎感兴趣的用户。面向加拿大和美国提供送货服务的网店店主则可以定位到这两个国家。',
        '为了向符合地理位置设置的用户展示广告，系统会使用各种信号。由于这些信号各不相同，因此不能保证 100% 准确。为确保取得最佳效果，请检查各项指标并调整设置，以实现您的广告目标。'
      ].join('\n\n'),
    },
  },
  search: {
    bidStrategyType: {
      label: '出价策略',
    },
  },
  shopping: {
    bidStrategyType: {
      label: '出价策略',
      questionPopover: {
        title: '出价策略',
        content: '出价策略是指您如何为用户与您广告的互动付费。足够高的出价和高质量的产品数据可确保您的广告在广告竞价中获得较高排名。'
      },
    },
  },
  googleAdsSynced: '已推送Google Ads',
  pmax: {
    bidStrategyType: {
      label: '出价策略',
      questionPopover: {
        title: '您希望着重实现的目标是什么？',
        content: '请选择您希望该广告系列重点提升的指标，选好指标后，下面会显示有助于您优化该指标的出价选项。',
      }
    },
    targetRoas: {
      checkbox: '设置目标广告支出回报率（可选）',
      questionPopover: {
        title: '目标广告支出回报率',
        content: [
          '目标广告支出回报率是指您希望从向广告投入的每一元钱中获得的平均转化价值。该百分比值可以使用以下公式计算得出：转化价值 ÷ 广告支出 x 100% = 目标广告支出回报率百分比值',
          '例如，如果您的目标是每 1 元广告支出平均带来 5 元的销售额，那么您的目标广告支出回报率就是 500%。',
          'Google Ads 会对出价进行设置，以尽量达到此广告系列的平均广告支出回报率 (ROAS)。部分转化的回报率可能会高于或低于您的目标值。'
        ].join('\n\n'),
      },
    },
    targetCpa: {
      checkbox: '设置目标每次转化费用（可选）',
      questionPopover: {
        title: '目标每次转化费用',
        content: '“目标每次转化费用”出价策略会为您设置合适的出价，在不超出您所设的目标每次转化费用 (CPA) 的前提下，帮助您争取尽可能多的转化。部分转化的费用可能会高于或低于您的目标值。'
      },
    },
    headlines: {
      label: '标题',
      atLeast: '至少填写{num}个标题'
    },
    longHeadlines: {
      label: '长标题',
      atLeast: '至少填写{num}个长标题'
    },
    descriptions: {
      label: '广告内容描述',
      atLeast: '至少填写{num}个广告内容描述'
    },
    businessName: {
      label: '商家名称',
      hint: '商家名称是指贵商家或贵品牌的名称。在某些版式中，它可能会出现在您广告的文字中。',
      englishOnly: '请填写英文名称'
    },
    youtubeVideoIds: {
      label: 'Youtube视频',
      hint: '视频可让您在更多广告网络上投放广告，并且很有可能会提高成效。',
      hint1: '请填写Youtube视频url, 格式: https://www.youtube.com/watch?v=xxxxxxxxxxx',
      formatInvalid: 'Youtube视频格式不正确',
      atLeast: '至少填写{num}个视频'
    },
    marketingImages: {
      label: '图片',
      atLeastSquare: '至少需要一张方形图片',
      atLeastLandscape: '至少需要一张横向图片',
      maxNum: '最多20张图片',
      landscapeImage: '横向图片',
      squareImage: '方形图片',
      portraitImage: '纵向图片',
      recommendedSize: '推荐尺寸',
      minSize: '最小尺寸',
      optional: '（可选）',
      popover: {
        title: '添加符合推荐尺寸或可剪裁为推荐尺寸的图片。',
        content: '注意：任何图片的文件大小不得超过 5120 KB',
      }
    },
    logos: {
      label: '徽标',
      maxNum: '最多5张徽标',
      squareImage: '方形徽标',
      landscapeImage: '横向徽标',
    },
    searchThemes: {
      label: '搜索主题',
      popover: {
        title: '用户在搜索您的产品或服务时会使用哪些字词或词组？',
        content: '搜索主题利用 Google 的搜索数据来发现更有可能完成转化的客户。这些信息会与您业务和目标方面的相关信息结合使用，以帮助取得更理想的成效。您的主题可以影响哪些人会在各个渠道中看到您的广告。'
      }
    },
    finalUrls: {
      label: '最终到达网址',
    },
    competingWebsites: {
      label: '竞争对手网站',
      popover: {
        content: '输入理想客户可能会访问的网站地址（网址）。这样，您的广告就会覆盖浏览类似网址（与您所输入的网址类似）的用户。注意：这并不意味着您的广告将在这些网址展示。'
      }
    },
    userListIds: {
      label: '细分受众',
    },
    audiences: {
      label: '受众群体信号'
    },
    imageCropper: {
      chooseImage: '选择图片',
      uploading: '正在上传...',
      chooseRatio: '选择比例',
    },
    feedLabel: {
      label: 'Feed 标签',
      hint: '请选择相应 Feed（其中包含您想在此广告系列中宣传的商品）',
      useAll: '使用所有产品 Feed'
    }
  },
  googleUserLists: {
    title: '客户名单',
    createTitle: '新建客户名单',
    updateTitle: '更新客户名单',
    push: '推送到Google Ads',
    detailTitle: '客户名单详情',
    name: {
      label: '细分受众群名称',
    },
    uploadFile: {
      label: '上传数据',
      requiredMessage: '请上传文件',
      hint: '请先{url}，填写完整信息后上传。',
      downloadTpl: '下载模板',
    },
    description: {
      label: '描述'
    },
    customerTypes: {
      label: '客户类型'
    },
    status: {
      label: '状态',
    }
  }
};
