import { request } from '@/utils';
import { ref } from 'vue';
import { MerchantMetaobjectType, getMerchantMetaobjectTypeDefaultData } from '@/models/merchant-metaobject';
import type { AttachmentType } from '@/models/attachment';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';
import { ElNotification, ElMessageBox } from 'element-plus';

export function useMetaobejct(merchantId: string | number) {
  const merchantMetaobject = ref<MerchantMetaobjectType>(getMerchantMetaobjectTypeDefaultData());
  const loading = ref(false);
  async function fetchData() {
    try {
      loading.value = true;
      const { data } = await request.get<MerchantMetaobjectType>(`merchants/${merchantId}/color_value`);
      merchantMetaobject.value = data;
      form.value = _.pick(data, ['size_table', 'swatch', 'color_option_name']);
    } finally {
      loading.value = false;
    }
  }

  interface FormType {
    color_option_name?: MerchantMetaobjectType['color_option_name'];
    size_table?: AttachmentType;
    swatch?: MerchantMetaobjectType['swatch'];
  }

  const { t } = useI18n();
  const form = ref<FormType>({});
  const submitting = ref(false);
  async function updateMetaobject(execSync = false) {
    submitting.value = true;
    try {
      await request.put(`/merchants/${merchantId}/color_value`, {
        ...form.value,
        size_table: form.value.size_table?.signed_id || null,
      });
      if (execSync) {
        await sync();
      }
      ElNotification.success(t('notification.update_success'));
    } finally {
      submitting.value = false;
    }
  }

  async function sync() {
    try {
      await request.put(`/merchants/${merchantId}/sync_color_value`, {}, { loading: { silent: true } });
    } catch (e: any) {
      const code = e?.code;
      const message = e?.message;
      if (String(code) === '40004') {
        const url = _.get(e, 'response.data.detail.url');
        await ElMessageBox.confirm(message, {
          confirmButtonText: t('btn_auth'),
        });
        if (url) {
          window.open(url);
        }
      } else {
        ElMessageBox.alert(message);
      }
      throw e;
    }
  }

  return {
    metaobject: merchantMetaobject,
    fetchData,
    loading,
    submitting,
    updateMetaobject,
    form,
  };
}
