import { BaseType, getBaseData } from './base';
import type { AttachmentType } from './attachment';

export interface MerchantMetaobjectType extends BaseType {
  size_table?: AttachmentType;
  swatch: Record<string, string>;
  color_option_name?: string;
}

export function getMerchantMetaobjectTypeDefaultData(): MerchantMetaobjectType {
  return {
    ...getBaseData(),
    swatch: {},
  };
}
