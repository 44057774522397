import type { AritTask } from '@/models/arit-task';
import { type Ref, computed } from 'vue';
import dayjs from 'dayjs';

export function useReport(reportData: Readonly<Ref<AritTask['report_data']>>) {
  const domain = computed(() => reportData.value?.site_link.replace(/^(http|https):\/\//, ''));
  const createTimeFormated = computed(() => dayjs(reportData.value?.created_time).format('YYYY-MM-DD HH:mm'));
  const checkItems = computed(() => reportData.value?.note.split('|').slice(0, 2));

  return {
    domain,
    createTimeFormated,
    checkItems,
  };
}
