import { BaseType, getBaseData } from './base';

export interface IndustryType extends BaseType {
  name: string;
}

export function getIndustryDefaultData(): IndustryType {
  return {
    ...getBaseData(),
    name: '',
  };
}
