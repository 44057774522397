import { BaseType, getBaseData } from './base';
import { AttachmentType, getAttachmentDefaultData } from './attachment';
import { ThemeCategoryType, getThemeCategoryDefaultData } from './theme-category';

export interface ThemeType extends BaseType {
  name: string;
  platform: 'shopify' | 'shoplazza';
  theme_category_id: number;
  demo_store_url: string;
  theme_category: ThemeCategoryType;
  cover: AttachmentType;
  images?: AttachmentType[];
  file: AttachmentType;
  preview_file?: AttachmentType;
}

export function getThemeDefaultData(): ThemeType {
  return {
    ...getBaseData(),
    name: '',
    platform: 'shopify',
    theme_category_id: 0,
    demo_store_url: '',
    theme_category: getThemeCategoryDefaultData(),
    cover: getAttachmentDefaultData(),
    file: getAttachmentDefaultData()
  };
}
