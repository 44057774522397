import { sleep } from './sleep';
import _ from 'lodash';

export function polling(fn = _.noop, wait: number = 3000) {
  let canceled = false;
  const exec: () => void = async () => {
    if (!canceled) {
      try {
        await fn();
      } catch (e) {}
      await sleep(wait);
      return exec();
    }
  };
  sleep(wait).finally(exec);
  const cancel = () => canceled = true;
  return cancel;
}
