export default {
  'title': '网站检测',
  'new': '新建网站检测任务',
  'form_info': '请核对并完整填写表单信息以符合规范的必要验证，完整填写“非必填项(没有*标记)”内容，将有助于我们更快且更准确地获取审核结果。',
  'brand_names': '品牌信息',
  'brand_names_tip': '请输入您的品牌名称或售卖的产品品牌名称',
  'product_details_page_link': '产品详情页URL',
  'product_details_page_link_tip': '请以http或https开头，若无产品详情页请填入首页网址',
  'product_list_page_link': '产品列表页URL',
  'product_list_page_link_tip': '请以http或https开头，若无产品列表请填入首页网址',
  'site_link': '首页URL',
  'site_link_tip': '请以http或https开头，例如 https://truemetrics.cn',
  'has_gdpr_regions': '是否已经或计划投放欧盟国家',
  'has_shopping_ads': '网站是否有购物车或支持线上交易',
  'about_us_page_link': '关于我们页URL',
  'contact_us_page_link': '联系我们页URL',
  'gdpr_page_link': '数据安全说明页URL',
  'gdpr_page_link_tip': '请以http或https开头，符合GDPR数据安全和Cookie管理和应用的说明页URL',
  'pay_page_link': '支付说明页URL',
  'privacy_page_link': '信息安全页URL',
  'privacy_page_link_tip': '例如隐私说明，请以http或https开头',
  'refund_page_link': '退款说明页URL',
  'return_page_link': '退换货说明页URL',
  'shipping_page_link': '运费说明页URL',
  'task_status': '状态',
  'task_status_true': '已完成',
  'task_status_false': '执行中',
  'site_origin': '网站域名',
  'create_success': '任务创建成功，请等待扫描完成',
  'btn_download': '下载报告',
  'page_detail': {
    'website_features': '网站功能',
    'privacy_security': '隐私安全',
    'website_performance_experience': '网站性能体验',
    'shopping_ad_risk_check': '购物广告风险检查',
    'summary_1': '必须满足项已全部检查通过！',
    'summary_2': '烦请自查，若不适用或自查后实际符合要求，可自行决定上线。',
    'summary_3': '请查看待修复项目，项目修复后再次提交审核！',
    'disclaime': '免责声明:',
    'disclaime_desc': '该工具不会就谷歌广告政策合规性提供评论或评估建议，感谢您的理解！谷歌广告政策不定期会有更新，请关注索引链接获取最准确的官方说明。',
    'item': '项',
    'items': '项目',
    'report_overview': '报告概览',
    'evaluation_results': '评估结果',
    'no_risk': '无风险',
    'risk_identified': '有风险',
    'audit_results': '审计结果',
    'audit_items': '审计项目',
    'reference_links': '参考链接',
    'attachments': '附件',
    'website_error_details': '网站错误详情',
    'mandatory_requirements': '必须满足项',
    'suggested_requirements': '建议满足项（不影响综合评分）',
    'tip': '提示'
  },
  'report_data': {
    'id': '报告ID',
    'summary_score': '得分',
    'note': '通过率',
    'task_info': '任务ID',
    'create_time': '报告生成时间'
  },
  'detail_title': '网站检测结果报告',
  'report_checks': {
    'has_cta': '产品详情页是否有行动引导按钮',
    'has_cta_tip': '建议突出行动引导按钮',
    'has_concat_us_page': '是否提供联系我们说明页面',
    'has_concat_us_page_tip': '建议提供联系方式说明',
    'has_phone': '联系我们页面是否提供中国联系电话',
    'has_phone_tip': '建议提供中国联系电话',
    'has_email': '联系我们页面是否提供联系邮箱',
    'has_email_tip': '建议提供联系邮箱',
    'has_cn_address': '联系我们页面是否提供中国联系地址',
    'has_cn_address_tip': '建议提供联系地址',
    'has_about_page': '是否提供关于我们说明页面',
    'has_about_page_tip': '建议提供关于我们页面',
    'about_year': '关于我们页面是否提供公司运营年份',
    'about_year_tip': '建议提供有效公司成立年份',
    'has_about_country': '关于我们页面是否提供公司运营所在国家',
    'has_about_country_tip': '建议提供真实公司运营国家',
    'has_search': '首页是否提供搜索功能',
    'has_search_tip': '建议支持站内搜索',
    'has_filter': '产品列表页是否提供筛选功能',
    'has_filter_tip': '建议支持产品筛选功能',
    'has_sort': '产品列表页是否提供排序功能',
    'has_sort_tip': '建议支持产品排序功能',
    'has_return_page': '是否提供退换货说明页面',
    'has_return_page_tip': '建议提供退换货说明',
    'has_return_contact': '退换货页面是否提供退换货地址或联系方式',
    'has_return_contact_tip': '建议提供退换货联系方式',
    'has_return_shipping': '退换货页面是否提供退换货运费说明',
    'has_return_shipping_tip': '建议提供退换货运费说明',
    'has_return_freight_carrier': '退换货页面是否说明退换货运费承担方',
    'has_return_freight_carrier_tip': '建议说明退换货运费承担方',
    'has_shipping_page': '运输信息页是否提供运费说明页面',
    'has_shipping_page_tip': '建议提供运费说明',
    'has_freight_desc': '运输信息页是否提供免邮条件说明',
    'has_freight_desc_tip': '建议提供免邮条件说明',
    'has_shipping_desc': '运输信息页是否提供各国运达费用及时间信息',
    'has_shipping_desc_tip': '建议提供不同国家地区运费信息',
    'has_order_shipping_time': '运输信息页是否提供订单处理时间和运送时间说明',
    'has_order_shipping_time_tip': '建议提供订单处理时间和运送时间说明',
    'has_payment_page': '支付信息页是否提供付款说明页面',
    'has_payment_page_tip': '建议提供付款说明页',
    'has_payment': '首页下端是否提供支付方式标识',
    'has_payment_tip': '建议首页底部补充支付方式标识',
    'has_sales': '网站是否含有促销内容',
    'has_sales_tip': '请避免使用误导性的或不切实际的宣传内容',
    'has_infringement_imgs': '网站是否存在网站素材侵权风险',
    'has_infringement_imgs_tip': '随机抽查结果：存在与外部资源相似度较高的有图片，详情参考报告底部附件，请人工二次核验，避免侵权风险',
    'has_social': '首页社交媒体标签是否有可正常打开',
    'has_social_tip': '有社交链接但都无法正常打开',
    'has_privacy_page': '是否提供隐私保护说明页面',
    'has_privacy_page_tip': '建议提供隐私保护说明',
    'has_gdpr_management_page': '是否有征求访客授权许可',
    'has_gdpr_management_page_tip': '建议补充访客授权征求管理功能',
    'has_privacy_concat': '隐私说明页面是否提供公司联系方式',
    'has_privacy_concat_tip': '建议隐私保护说明页提供公司联系方式',
    'is_ssl': '网站是否使用SSL协议',
    'is_ssl_tip': '建议使用SSL协议',
    'is_malicious': '网站安全得分',
    'is_malicious_tip': '审计详情',
    'is_brandname_in_domain': '品牌名关键字是否在域名中',
    'page_speed_score': 'PageSpeed评分',
    'page_speed_score_tip': '审计详情： PageSpeed',
    'is_mobile_friendly': '移动设备友好性',
    'is_mobile_friendly_tip': '审计详情：“移动设备友好性”',
    'visit_seconds_avg': '产品页是否可访问',
    'visit_seconds_avg_tip': '对于提交到购物广告和非付费商品详情的商品，所有对应的着陆页都必须可供在任何地点购物的任何用户访问',
    'testmysite_3g_speed': '3G手机加载速度（秒）',
    'testmysite_4g_speed': '4G手机加载速度（秒）',
    'has_hp_html_error': '网站是否存在HTML或JS代码错误',
    'has_hp_html_error_tip': '错误详情详见报告底部附件',
    't_review_score': 'Trustpilot评分',
    'r_review_rating': 'ResellerRatings评分',
    's_review_rating': 'Sitejabber评分',
    'is_reachable': '着陆页是否可访问',
    'is_reachable_tip': '对于提交到购物广告和非付费商品详情的商品，所有对应的着陆页都必须可供在任何地点购物的任何用户访问',
    'outgong_links_rate': '着陆页外链占比',
    'outgong_links_rate_tip': '建议自查，网站需提供独特内容，避免大量广告和外部链接',
    'has_dangerous_content': '危险产品风险',
    'has_dangerous_content_tip': '网站检索到危险产品内容',
    'has_dishonest_content': '促成不诚实行为的产品风险',
    'has_dishonest_content_tip': '网站检索到促成不诚实行为的产品内容',
    'has_unsupported_content': '不受支持的购物广告内容',
    'has_unsupported_content_tip': '网站检索到不受支持的购物广告内容',
    'has_adult_content': '面向成人的内容',
    'has_adult_content_tip': '网站检索到面向成人的内容',
    'has_copyrighted_content': '受版权保护的内容',
    'has_copyrighted_content_tip': '网站检索到受版权保护的内容',
    'has_healthcare_medicines_content': '与医疗保健相关的内容',
    'has_healthcare_medicines_content_tip': '网站检索到与医疗保健相关的内容',
    'has_political_content': '政治内容',
    'has_political_content_tip': '网站检索到政治内容',
    'has_gambling_content': '与博彩相关的内容',
    'has_gambling_content_tip': '网站检索到与博彩相关的内容',
    'has_unsafe_server_data': '有通过非安全服务器连接获取数据风险',
    'has_unsafe_server_data_tip': '在处理客户的个人信息时，务必要使用安全的处理服务器',
    'outgong_links_sum': '着陆页外链总量',
    'is_safe': '安全',
    'is_unsafe': '不安全',
    'is_friendly': '友好',
    'is_unfriendly': '不友好',
    'none': '无',
    'has_error': '存在问题',
    'has_no_error': '正常',
    'sensitive_words_not_detected': '未检测到敏感词',
    'sensitive_words_detected': '检测到敏感词'
  },
  checklist: {
    'title': '上线检查Checklist',
    'tip': '请检查以下内容是否符合要求，确认后勾选再提交',
    'checklist': [
      '联系方式是否至少提供了2种，且邮箱为公司或品牌名后缀官方邮箱',
      '文字描述的支付方式是否网站底部的支付icon一一对应',
      '可送货的国家和区域是否清晰，避免出现etc这类字眼',
      '退换货运费由商家还是用户承担必须写清楚',
      '退款到账时间必须写出天数范围',
      '无虚假的评论',
      '无IP地址限制',
      '没有对不同IP地址的用户显示不同的内容',
      '无抄袭的图片素材',
      '无产品做划线价直降（如为新站）',
    ]
  }
};
