import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';
import { omit } from 'lodash';


export class RoleKindsStore extends SimpleStore {
  data: Record<string, string> = {};

  async fetch() {
    const res = await request.get<typeof this.data>('/agencies/role_kind_options');
    this.data = res.data;
    return res;
  }

  get roles() {
    return omit(this.data, 'super');
  }
};

export const roleKindsStore = RoleKindsStore.create<RoleKindsStore>();
