import _ from 'lodash';

export function createRichTextRequiredValidator(message = '') {
  return {
    validator: (rule: any, value: any, callback: any) => {
      const div = document.createElement('div');
      div.innerHTML = value || '';
      if (!_.trim(div.innerText)) {
        callback(message);
      } else {
        callback();
      }
    }
  };
}
