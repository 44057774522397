import { BaseType, getBaseData } from './base';
import { type AttachmentType } from './attachment';
import { REVIEW_STATE } from '@/constants';

export interface ReviewType extends BaseType {
  body: string;
  master_asin: string;
  name: string;
  rating: number;
  shop: string;
  state: keyof typeof REVIEW_STATE;
  title: string;
  files: Array<AttachmentType>;
  product_id: string;
  abilities?: {
    update: boolean;
    destroy: boolean;
  };
}

export function getReviewDefaultData(): ReviewType {
  return {
    ...getBaseData(),
    body: '',
    master_asin: '',
    name: '',
    rating: 0,
    shop: '',
    state: 'hide',
    title: '',
    files: [],
    product_id: '',
  };
}
