export default {
  'size_table_image': 'Size Table Image',
  'swatch': 'Color Multi-Attribute Name',
  'get_color_names': 'Get Colors',
  'color_values': 'Colors',
  'btn_update_and_sync': 'Update and sync to Shopify',
  'blank_hidden_swatch': 'Leave blank to not display color swatches',
  'blank_hidden_size_table': 'Leave blank to not display size chart',
  'size_table_recommend': 'Recommended size 790 x any height.',
  'btn_view_example_img': 'View sample images',
  'sync_tip_1': 'Update the information you have filled out and sync the data to Shopify.',
  'sync_tip_2': 'The synced data can be viewed at the location shown in the screenshot. (Click on the image to view a larger version)',
  'color_map': 'Color Map',
  'color_map_tip': 'Used to display color blocks corresponding to the names on the swatch',
  'color_name': 'Color Name',
  'color_value': 'Color Value',
  'swatch_tips': {
    'func_desc': 'Display all colors of this product on the Shopify product card.',
    'color_option_name_1': 'The name used to group multiple attributes of the product on Shopify. (Click on the image to view a larger version)',
    'color_option_name_2': 'For example, Color: Red, Green; Size: Big, Small. Here, Color and Size are the names of multiple attributes, with Color being the color attribute name.',
    'get_colors': 'This step will retrieve all color attribute values, such as Red, Green, from your store\'s products.'
  },
  'size_table_tips': {
    'func_desc': 'Display a size chart button on the Shopify product details, which when clicked, shows the size image you uploaded.'
  },
  'add_color': 'Add Color',
  'color_name_required': 'Color name cannot be empty',
  'color_value_required': 'Color value cannot be empty'
};
