import { BaseType, getBaseData } from './base';
import { IndustryType, getIndustryDefaultData } from './industry';
import { MIGRATE_STATUS, OVERALL_ADJUSTMENT_PRICE_TYPE, MIGRATE_FROM, MIGRATE_TO, GOOGLE_INTEGRATE_STATUS, AUTOMATION_COMPAIGN_BUSSINESS_TYPE } from '@/constants';
import { OAuthIdentityType } from './oauth-identity';
import { AppealLetterType } from './appeal-letter';


export interface MerchantType extends BaseType {
  email: string;
  name: string;
  phone: string;
  type: string;
  agency_id: number;
  application_id: number;
  is_block: boolean;
  migrate_from: keyof typeof MIGRATE_FROM;
  migrate_to: keyof typeof MIGRATE_TO;
  migrate_status: keyof typeof MIGRATE_STATUS;
  theme_id: number;
  overall_adjustment_price: boolean;
  overall_adjustment_price_type: keyof typeof OVERALL_ADJUSTMENT_PRICE_TYPE;
  overall_adjustment_price_value: number;
  brand_name: string;
  company_name: string;
  company_address: string;
  company_established: string;
  return_address: string;
  return_phone: string;
  return_contact: string;
  return_validity_time: number;
  return_email: string;
  industry_id: number;
  industry: IndustryType;
  google_manager_id: string;
  google_customer_id: string;
  google_ads_tracking_code: string;
  google_analytics_v4_tracking_code: string;
  google_analytics_tracking_code: string;
  google_integrate_status: keyof typeof GOOGLE_INTEGRATE_STATUS;
  default_migration_task_id?: number;
  about_us: string;
  contact_us: string;
  refund_policy: string;
  privacy_policy: string;
  terms_of_service: string;
  shipping_policy: string;
  amazon_sp_authorized: string;
  alibaba_authorized: string;
  shopify_authorized: boolean;
  shoplazza_authorized: boolean;
  shopify_oauth_identity: null | OAuthIdentityType;
  shoplazza_oauth_identity: null | OAuthIdentityType;
  first_visit_migration_page_at?: string;
  first_visit_google_integration_page_at?: string;
  first_visit_google_campaign_page_at?: string;
  google_campaign_progress: number;
  latest_automation_campaign_id?: number;
  auto_sync: boolean;
  ai_optimize_product_title: boolean;
  product_image_resize_width?: number;
  product_image_resize_height?: number;
  appeal_letter: null | Partial<AppealLetterType>;
  can_enable_auto_sync: boolean;
  abilities?: {
    enable_auto_sync: boolean,
    assign: boolean;
    update: boolean;
    destroy: boolean;
    update_google_integration: boolean;
    update_google_campaign: boolean;
    migrate: boolean;
    read_review: boolean;
    create_review: boolean;
    sync_review: boolean;
    read_metaobject: boolean;
    read_suggestion: boolean;
    generate_access_token: boolean;
    create_tamar_ai_account: boolean;
  };
  migrate_review_status: 'pending' | 'completed';
  seed_keywords?: string[];
  competing_websites?: string[];
  location_ids?: number[];
  campaign_category_name?: string;
  home_page_url: string;
  business_type?: keyof typeof AUTOMATION_COMPAIGN_BUSSINESS_TYPE,
  first_visit_arit_page_at?: string;
  update_adsport_complete_at?: string;
  product_type?: string;
  language_id?: number;
  currency?: string;
  tamar_ai_account_id?: number;
}

export function getMerchantDefaultData(): MerchantType {
  return {
    ...getBaseData(),
    email: '',
    name: '',
    phone: '',
    type: '',
    agency_id: 0,
    application_id: 0,
    is_block: true,
    migrate_from: 'unknown',
    migrate_to: 'unknown',
    migrate_status: 'pending',
    theme_id: 0,
    overall_adjustment_price: false,
    overall_adjustment_price_type: 'increase_percentage',
    overall_adjustment_price_value: 0,
    brand_name: '',
    company_name: '',
    company_address: '',
    company_established: '',
    return_address: '',
    return_phone: '',
    return_contact: '',
    return_validity_time: 1,
    return_email: '',
    industry_id: 0,
    industry: getIndustryDefaultData(),
    google_manager_id: '',
    google_customer_id: '',
    google_ads_tracking_code: '',
    google_analytics_v4_tracking_code: '',
    google_analytics_tracking_code: '',
    google_integrate_status: 'pending',
    about_us: '',
    contact_us: '',
    refund_policy: '',
    privacy_policy: '',
    terms_of_service: '',
    shipping_policy: '',
    amazon_sp_authorized: '',
    alibaba_authorized: '',
    shopify_authorized: false,
    shoplazza_authorized: false,
    shopify_oauth_identity: null,
    shoplazza_oauth_identity: null,
    google_campaign_progress: 0,
    auto_sync: false,
    ai_optimize_product_title: false,
    appeal_letter: null,
    can_enable_auto_sync: false,
    migrate_review_status: 'pending',
    home_page_url: '',
  };
}
