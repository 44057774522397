import { SimpleStore } from './helper/simple-store';
import { UserType, getUserDefaultData } from '@/models/user';
import { request } from '@/utils';
import router from '@/router';

const { Storage } = require('@/utils/storage');

export class AuthStore extends SimpleStore {
  constructor() {
    super();
    this.ACCESS_TOKEN_KEY = `${process.env.VUE_APP_ENV}_USER_ACCESS_TOKEN_DATA`;
    this.storage = new Storage(this.ACCESS_TOKEN_KEY);
  }
  ACCESS_TOKEN_KEY: string;
  storage: Storage
  user: UserType = getUserDefaultData()
  $access_token: string = ''

  set access_token(v: string) {
    this.$access_token = v;
    this.storage.set(this.ACCESS_TOKEN_KEY, v);
  }

  get access_token(): string {
    this.$access_token = this.$access_token || this.storage.get(this.ACCESS_TOKEN_KEY) || '';
    return this.$access_token;
  }

  get isLogin() {
    return !!this.access_token;
  }

  get googleAuthed() {
    return this.user.google_authorized;
  }

  async fetch() {
    const res = await request.get<UserType>('/mine');
    this.user = res.data;
    return res;
  }

  // 主要是给轮询方法用的，不需要把错误抛出来
  async fetchMineSilence() {
    const { data } = await request.get<UserType>('/mine', { loading: { silent: true }, timeout: 10 * 1000 });
    this.user = data;
  }

  async signIn(form: { login_name: string; password: string }) {
    const { data } = await request.post('sessions', form);
    this.access_token = data.token;
    this.user = data.user;
  }

  signOut() {
    this.access_token = '';
    this.user = getUserDefaultData();
    this.isFulfilled = false;
    // 商家登录态过期了也先跳到登录
    router.replace({ name: 'login' });
  }
}

export const authStore = AuthStore.create<AuthStore>();
