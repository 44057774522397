export function downloadFromBlob(content: Blob, fileName: string) {
  const href = URL.createObjectURL(content);
  downloadFromUrl(href, fileName);
  URL.revokeObjectURL(href);
}

export function downloadFromUrl(url: string, fileName: string, target: '_blank' | '_self' = '_self') {
  const link = document.createElement('a');
  link.target = target;
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
