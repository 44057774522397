export default {
  'require': '请填写{name}',
  'upload_require': '请上传{name}',
  'invalid_format': '{name}格式不正确',
  'min_length': '{name}至少{num}个字符',
  'max_length': '{name}最多{num}个字符',
  'required': '请填写',
  'en_address': '请填写英文地址(支持数字，大小写字母，空格，" . "，" \' "，" - " )',
  'en_name': '格式不正确（支持大小写字母，空格，至少2个字符）',
  'maximum_upload_file_count': '最多可上传{count}个文件',
  'upload_file_size_invalid': '文件 - {filename} 大小不能超过 {size}',
  'url_is_invalid': '链接格式不正确，请以http或https开头',
  'email_is_invalid': '请输入有效的邮箱地址',
  'image_size_too_small': '图片 - {filename} 尺寸不能小于 {minWidth}x{minHeight}',
};
