export default {
  'account_setup': 'Account Setup',
  'checkout_page_tracking': 'Enable Enhanced Conversion',
  'install_google_app': 'Install GoogleChannel App',
  'select_ads_manager_account': 'Agency select your Google Ads manager account',
  'yes': 'Yes',
  'no': 'No',
  'name': 'Name',
  'email': 'Email',
  'account_id': 'Account ID',
  'google_analytics': 'Google Analytics',
  'ga_id': 'The Tracking ID of Google Analytics',
  'ga4_id': 'Measurement ID of Google Analytics4',
  'checkout_page_tracking_setting': 'Enable Enhanced Conversion',
  'copy_code': 'Copy the tracking code',
  'nav_to_paste_code': 'Take me to the page',
  'install_google_channel_app': 'Install Google Channel App',
  'nav_to_install_app_page': 'Take me to the page',
  'request_conversion_code': 'Request Conversion Code',
  'select_customer_id': 'Select Customer ID'
};
