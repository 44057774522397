import { BaseType, getBaseData } from './base';
import { OAuthIdentityType } from './oauth-identity';

export interface AgencyType extends BaseType {
  email: string;
  name: string;
  type: string;
  application_id: number;
  is_block: boolean;
  role_kinds: string[];
  google_authorized: boolean;
  google_oauth_identity: null | OAuthIdentityType;
  abilities?: {
    destroy: boolean;
    update: boolean;
  };
}

export function getAgencyDefaultData(): AgencyType {
  return {
    ...getBaseData(),
    email: '',
    name: '',
    type: '',
    application_id: 0,
    is_block: false,
    role_kinds: [],
    google_authorized: false,
    google_oauth_identity: null,
  };
}
