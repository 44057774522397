import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';
import { CustomVariableType } from '@/models/custom-variable';

export class CustomVariablesStore extends SimpleStore {
  data: CustomVariableType[] = [];

  async fetch() {
    const { data } = await request.get<CustomVariableType[]>('custom_variables', { params: { page: 0 } });
    this.data = data;
  }
}

export const customVariablesStore = CustomVariablesStore.create<CustomVariablesStore>();
