import _ from 'lodash';
import qs from 'qs';
import axios, { type AxiosResponse } from 'axios';
import { ElMessageBox, ElNotification } from 'element-plus';
import { authStore } from '@/stores';
import i18n from '@/i18n';
import router from '@/router';

const baseURL = '/app_api/v1';

export const request = axios.create({
  baseURL,
  timeout: 120 * 1000,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer(params: object) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

request.interceptors.request.use(config => {
  if (config.baseURL === baseURL) {
    if (config.headers) {
      // 如果query上有token 优先用query的
      const token = (router.currentRoute?.value?.query?.token as string) || authStore.access_token;
      if (token && !config.headers['Authorization']) {
        config.headers['Authorization'] = token;
      }
      config.headers['Locale'] = i18n.global.locale.value;
    }
  }
  return config;
});

function handleHeaderError(res: AxiosResponse) {
  const xErrorCount = _.get(res, 'headers[\'x-error-count\']');
  const xErrorMessage = _.get(res, 'headers[\'x-error-message\']');
  if (xErrorCount && xErrorMessage) {
    const errMsg = decodeURIComponent(xErrorMessage);
    if (_.get(res, 'config.loading.silent') !== true) {
      ElMessageBox.alert(errMsg, i18n.global.t('message_box.error'));
    }
    throw new Error(errMsg);
  }
}

request.interceptors.response.use(
  (res: any) => {
    handleHeaderError(res);
    return res;
  },
  async (err: { message: any; code: number; status: any; [key: string]: any }) => {
    handleHeaderError(err.response as unknown as AxiosResponse);

    const response = _.get(err, 'response', {});
    if (response.data) {
      const { error_message, messages, error, code } = response.data;
      err.message = error_message || messages || error || err.message;
      err.code = code;
    }
    err.status = response.status;
    // 如果query上有token 是商家用的链接 不跳转登录页
    const linkToken = router.currentRoute?.value?.query?.token as string;
    if (!linkToken) {
      if (response.status === 401) {
        _.set(err.config, 'headers[Authorization]', '');
        await authStore.signOut();
        if (_.get(err.config.loading, 'silent') !== true) {
          ElNotification.info(err.message);
        }
        return Promise.reject(err);
      }
    }
    if (_.get(err.config.loading, 'silent') !== true) {
      ElMessageBox.alert(err.message, i18n.global.t('message_box.error'));
    }
    return Promise.reject(err);
  }
);
