import { BaseType, getBaseData } from './base';

export interface ThemeCategoryType extends BaseType {
  name: string;
}

export function getThemeCategoryDefaultData(): ThemeCategoryType {
  return {
    ...getBaseData(),
    name: ''
  };
}
