export default {
  title: '亚马逊店铺选品',
  button_auth: '去授权',
  auth_success: '已授权',
  fields: {
    customer_name: '客户商业名称',
    email: '客户邮箱',
    phone_number: '电话号码',
    report_status: '报告状态',
    account_list: {
      label: '账户列表',
      fields: {
        account_name: '账户名称',
        region: '区域',
        seller_id: '卖家 ID',
        store_list: {
          label: '店铺列表',
          fields: {
            account_name: '账户名称',
            region: '区域',
            country: '国家',
            is_ad_api_authorized: '广告账户授权',
            is_sp_api_authorized: '卖家账户授权',
            ad_oauth_url: '广告 API 授权 URL',
            sp_oauth_url: 'SP API 授权 URL',
            store_id: '店铺 ID',
          },
        },
      },
    },
  },
  form: {
    title: '亚马逊店铺选品账户创建',
  },
  show: {
    title: '亚马逊店铺选品',
  },
  add_store: {
    title: '添加店铺',
    account_name: '账户名称',
    store_type: '店铺类型',
    region: '区域',
    redirect_url: '重定向 URL',
  },
  actions: {
    generate_report: '生成报告',
    regenerate_report: '重新生成报告',
    sync_account_list: '同步授权状态',
    generate_access_token: '获取商家授权链接',
    download_report: '下载报告',
  },
};
