import ejs from 'ejs';
import _ from 'lodash';
import { MerchantType } from '@/models/merchant';
import { POLICY_VAR_CLASS_NAME } from '@/constants';
import { v4 as uuidv4 } from 'uuid';
import { customVariablesStore } from '@/stores';
import { CustomVariableType } from '@/models/custom-variable';

export function decodeHTMLStr(str: string = '') {
  return _.unescape(str);
}

export async function getMerchantEjsTplData(merchant: MerchantType) {
  await customVariablesStore.tryFetchData();
  const oauthIdentity = {
    unknown: null,
    shopify: merchant.shopify_oauth_identity,
    shoplazza: merchant.shoplazza_oauth_identity
  }[merchant.migrate_to];

  const mapValues = (obj: { [key: string]: any }, namespace?: string) => {
    return _.mapValues(
      obj,
      // 加一个 zero width space占位
      // '_' 开头的不添加span标签（有些变量是当属性插进去的）
      (val, key) => key.startsWith('_') ? val : `<span class="${POLICY_VAR_CLASS_NAME}" data-key="${namespace ? `${namespace}.${key}` : key }" ${namespace ? `data-initvalue="${val}"` : ''}>&#8203;${val || ''}</span>`
    );
  };
  const mapObjWithUnderscore = (obj: { [key: string]: any }) => {
    const underscoredObj = _.cloneDeep(obj);
    _.each(obj, (val, key) => {
      underscoredObj[`_${key}`] = val;
    });
    return underscoredObj;
  };

  return {
    ...mapValues(
      mapObjWithUnderscore(
        {
          ...merchant,
          platform_host: oauthIdentity?.primary_uid || ''
        }
      )
    ),
    custom: mapValues(
      mapObjWithUnderscore(
        _.mapValues(_.keyBy(customVariablesStore.data, 'key'), (item: CustomVariableType) => item.value)
      )
    , 'custom')
  };
}

export async function renderPolicyTemplate(merchant: MerchantType, template: string = '') {
  const data = await getMerchantEjsTplData(merchant);
  const htmlstr = ejs.render(decodeHTMLStr(template), data);
  // 给每个变量加唯一id
  const div = document.createElement('div');
  div.innerHTML = htmlstr;
  _.each(div.querySelectorAll(`.${POLICY_VAR_CLASS_NAME}`), item => item.id = uuidv4());
  const result = div.innerHTML;
  div.remove();
  return result;
}

export async function renderInstructionTemplate(merchant: MerchantType, template: string = '') {
  const data = await getMerchantEjsTplData(merchant);
  return ejs.render(decodeHTMLStr(template), data);
}

export async function getPolicyTemplateVariables(merchant: MerchantType, template: string = '') {
  const str = await renderPolicyTemplate(merchant, template);
  const document = new DOMParser().parseFromString(str, 'text/html');
  const varEles = document.querySelectorAll<HTMLElement>(`.${POLICY_VAR_CLASS_NAME}`);
  return _.uniq(
    _.map(varEles, item => item.dataset.key || '')
  ).filter(Boolean);
}
