export default {
  'account_setup': '账户设置',
  'checkout_page_tracking': '启用增强转换',
  'install_google_app': '安装Google Channel App',
  'select_ads_manager_account': '选择您的 Google Ads 经理帐号',
  'yes': '是',
  'no': '否',
  'name': '名字',
  'email': '邮箱',
  'account_id': '账户ID',
  'google_analytics': 'Google Analytics',
  'ga_id': 'Google Analytics ID',
  'ga4_id': 'Google Analytics4 ID',
  'checkout_page_tracking_setting': '启用增强转换',
  'copy_code': '复制代码',
  'nav_to_paste_code': '去页面安装代码',
  'install_google_channel_app': '安装 Google Channel App',
  'nav_to_install_app_page': '跳转页面去安装',
  'request_conversion_code': '请求 conversion code',
  'select_customer_id': '选择 customer ID'
};
