export default {
  title: 'Amazon Store Selection',
  button_auth: 'Authorize',
  auth_success: 'Authorized',
  fields: {
    customer_name: 'Customer Business Name',
    email: 'Customer Email',
    phone_number: 'Phone Number',
    report_status: 'Report Status',
    account_list: {
      label: 'Account List',
      fields: {
        account_name: 'Account Name',
        region: 'Region',
        seller_id: 'Seller ID',
        store_list: {
          label: 'Store List',
          fields: {
            account_name: 'Account Name',
            region: 'Region',
            country: 'Country',
            is_ad_api_authorized: 'Ad Account Authorized',
            is_sp_api_authorized: 'Seller Account Authorized',
            ad_oauth_url: 'Ad API Authorization URL',
            sp_oauth_url: 'SP API Authorization URL',
            store_id: 'Store ID',
          },
        },
      },
    },
  },
  form: {
    title: 'Create Amazon Store Selection Account',
  },
  show: {
    title: 'Amazon Store Selection Details',
  },
  add_store: {
    title: 'Add Store',
    account_name: 'Account Name',
    store_type: 'Store Type',
    region: 'Region',
    redirect_url: 'Redirect URL',
  },
  actions: {
    generate_report: 'Generate Report',
    regenerate_report: 'Regenerate Report',
    sync_account_list: 'Sync Authorization Status',
    generate_access_token: 'Get Merchant Authorization Link',
    download_report: 'Download Report',
  },
};
