import { request } from './request';
import { type StorefrontAccesssTokenType } from '@/models/storefront-access-token';

let client: ReturnType<Window['ShopifyStorefrontAPIClient']['createStorefrontApiClient']>;
export async function getClient(shop: string, merchantId: string | number, force = false) {
  if (!force && client) {
    return client;
  }
  const { data } = await request.get<StorefrontAccesssTokenType>(`/merchants/${merchantId}/storefront_access_token`);
  // 从@shopify/storefront-api-client引入的build后request会报错  不知道为什么
  // 现在是从public/storefront-api-client.js 引入的文件
  client = window.ShopifyStorefrontAPIClient.createStorefrontApiClient({
    storeDomain: shop,
    apiVersion: '2024-04',
    publicAccessToken: data.access_token,
  });
  return client;
}
