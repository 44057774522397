import { BaseType, getBaseData } from './base';
import { OVERALL_ADJUSTMENT_PRICE_TYPE, MIGRATE_TASK_STATUS } from '@/constants';

export interface MigrationTaskType extends BaseType {
  overall_adjustment_price: boolean;
  overall_adjustment_price_type: keyof typeof OVERALL_ADJUSTMENT_PRICE_TYPE;
  overall_adjustment_price_value: number;
  ai_optimize_product_title: boolean;
  migrate_status: keyof typeof MIGRATE_TASK_STATUS;
  migration_progress: string;
  migration_info_html: string;
  product_image_resize_width?: number;
  product_image_resize_height?: number;
}

export function getMigrationTaskDefaultData(): MigrationTaskType {
  return {
    ...getBaseData(),
    overall_adjustment_price: false,
    overall_adjustment_price_type: 'increase_percentage',
    overall_adjustment_price_value: 0,
    migrate_status: 'pending',
    migration_progress: '0',
    migration_info_html: '',
    ai_optimize_product_title: false,
  };
}
